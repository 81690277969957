import {
  // CREATE DOCUMENT
  CREATE_DOCUMENT_ERROR,
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  // DELETE DOCUMENT
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
  GET_ALL_DOCUMENTS_SUCCESS,
} from './documents.actions'
import { DialogueDocument, NetworkState, DocumentSummary } from '../../types'
import { set, filter } from 'lodash-es'
import { AnyAction } from 'redux'

const initialState: NetworkState<Array<DialogueDocument>> = {
  loading: true,
}

export default function documentReducer(state = initialState, action: AnyAction) {
  const { payload, type } = action

  switch (type) {
    case GET_ALL_DOCUMENTS_SUCCESS:
      return { data: payload, loading: false }
    // CREATE NEW DOCUMENT
    case CREATE_DOCUMENT_REQUEST:
      return { ...state, loading: true }
    case CREATE_DOCUMENT_SUCCESS:
      return { ...state, loading: false }
    case CREATE_DOCUMENT_ERROR:
      return { ...state, loading: false, error: payload }
    // DELETE DOCUMENT
    case DELETE_DOCUMENT_REQUEST:
      return { ...state, loading: true }
    case DELETE_DOCUMENT_SUCCESS:
      if (!state.data) {
        return { ...state, loading: false }
      }
      const newState = set(state, 'data', filter(state.data, (doc: DocumentSummary) => doc.id !== payload))
      return { ...newState, loading: false }
    case DELETE_DOCUMENT_ERROR:
      return { ...state, loading: false, error: payload }
    default:
      return state
  }
}
