import React, { Component, ChangeEvent, HTMLProps } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ReactQuill, { Quill } from 'react-quill'
import { last, get } from 'lodash-es'
import Focus from 'quill-focus'

import * as QuillTypes from 'quill'

import { Styleable, EditorState, EditorMode, AppState } from '../types'
import {
  clearDocument,
  clearMlSuggestions,
  getDocument,
  setSuggestionsSettings,
  updateDocument,
  updateTitle,
} from '../store/editor/editor.actions'
import { RouteComponentProps } from 'react-router'
import { Loader } from './shared/loader.component'
import { propOr } from '../utils/logic.utils'
import { safetyOrange } from '../style/colors'
import { ContentWrapper, Content } from './shared/content.component'
import { Link } from 'react-router-dom'
import { Sidebar, sidebarWidth } from './shared/sidebar.component'
import { Gutters, RightGutterWidth } from './shared/gutters.component'
import { Button } from '../style/button'
import { FontStyle, Text } from '../style/typography'
import Footer from './Footer'
import { pingMLServer } from '../graphql/queries'

import { AnimatedLoader } from './shared/animated_loader.component'
import { documentsRef } from '../firebase/firebase'
import { Suggestions } from './shared/suggestions.component'

Quill.register('modules/focus', Focus)

type RouteParams = {
  documentId: string
}

type ThemeProps = {
  font: string
  colors: Array<string>
}

type State = {
  documentBody: string
  title: string
  range: QuillTypes.RangeStatic
  tooltip: {
    x: number
    y: number
  }
  editorMode: string
  showSidebar: boolean
  wordCount: number
  currentWordRange: {
    start: number
    end: number
    range: QuillTypes.RangeStatic
    scenarioType: string
  }
  theme?: ThemeProps
}

type Context = {
  collapsed: boolean
  empty: boolean
  format: any
  offset: number
  prefix: string
  suffix: string
}

type ConnectedDispatchProps = {
  clearDocument: (id: string) => void
  clearMlSuggestions: (documentId: string) => void
  getDocument: (id: string) => void
  updateDocument: (documentId: string, documentBody: string) => void
  updateTitle: (documentId: string, title: string) => void
  setSuggestionsSettings: (documentId: string, scenarioType: string, wordArray: Array<string>) => void
}

type Props = EditorState & ConnectedDispatchProps & RouteComponentProps<RouteParams> & Styleable

const EditorHeader = styled.div``
const EditorSaveText = styled.div``
const Settings = styled.div``
const DocumentTitle = styled.input``
const DocumentTitleGhost = styled.div``

const CancelButton = styled(Button)`
  color: ${safetyOrange};
  background: transparent;
  border: 2px solid ${safetyOrange};
  outline: none;
  border-radius: 3px;
  padding: 0.4rem;
  margin-top: 0.4rem !important;
`

const Tooltip = styled<HTMLProps<HTMLDivElement> & Pick<State, 'tooltip'>, 'div'>('div')`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(${propOr('tooltip.x', 0)}px, calc(${propOr('tooltip.y', 0)}px));
  z-index: 1;
`

const EditorContainer = styled.div``

const EditorWrapper = styled<ThemeProps, any>(ContentWrapper)``

export class RawEditor extends Component<Props, State> {
  state = {
    documentBody: '',
    title: '',
    tooltip: { show: false, x: 0, y: 0, options: [] },
    editorMode: EditorMode.Writing,
    showSidebar: false,
    wordCount: 0,
    range: {
      index: 0,
      length: 0,
    },
    currentWordRange: {
      scenarioType: '',
      start: 0,
      end: 0,
      range: {
        index: 0,
        length: 0,
      },
    },
  }
  quillRef: any
  reactQuillRef: any

  constructor(props: Props) {
    super(props)
    this.quillRef = null
    this.reactQuillRef = null
  }

  modules = {
    toolbar: null,
    clipboard: { matchVisual: false },
    focus: { focusClass: 'focused' },
    keyboard: {
      bindings: {
        save: {
          key: 's',
          shortKey: true,
          handler: () => {
            this.handleFirestoreUpdateDocument()
          },
        },
        getSuggestion: {
          key: 'enter',
          shortKey: true,
          handler: (range: QuillTypes.RangeStatic, context: Context) => this.handleQuickfix(range, context),
        },
      },
    },
  }

  formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link']

  static getDerivedStateFromProps(props: Props, state: State) {
    const title = get(props, 'document.data.documentMeta.title', '')
    const documentBody = get(props, 'document.data.docBody', '')

    if (!state.documentBody && props.document.data) {
      return { ...state, title, documentBody }
    }

    return { ...state }
  }

  async componentDidMount() {
    this.attachQuillRefs()

    document.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        this.closeTooltip()
      }
    })

    if (!get(this, 'props.document.data.docBody', null) && !this.props.document.loading) {
      await this.props.getDocument(this.props.match.params.documentId)
    }

    if (this.quillRef) {
      this.quillRef.focus()
    }

    this.setState(() => ({ wordCount: this.getWordCount() }))
  }

  componentWillUnmount() {
    this.handleFirestoreUpdateDocument()
    this.props.clearDocument(this.props.match.params.documentId)
  }

  componentDidUpdate() {
    this.attachQuillRefs()
  }

  //TODO Switch to Cloud Run
  handleChange = (text: string) => {
    if (get(this.props, 'document.data.mlJobRunning')) {
      return
    }

    this.setState(() => ({
      documentBody: text,
      wordCount: this.getWordCount(),
    }))
    // this.autoSave()
  }

  closeTooltip() {
    // this.setState(() => ({ tooltip: { ...this.state.tooltip, show: false } }))
    this.props.clearMlSuggestions(this.props.match.params.documentId)
  }

  handleTooltipOnBlur = () => {
    this.closeTooltip()
  }

  attachQuillRefs = () => {
    if (!this.reactQuillRef) return
    this.quillRef = this.reactQuillRef.getEditor()
  }

  //TODO Switch to Cloud Run
  handleSuggestionInsert = (suggestion: string) => {
    const { end, scenarioType, start } = this.state.currentWordRange
    this.closeTooltip()
    this.quillRef.focus()
    const newSuggestion = ` ${suggestion} `
    const top = get(document, 'documentElement.scrollTop')

    const docId = get(this.props, 'document.data.documentMeta.id')
    documentsRef.doc(docId).update({ suggestions: [] })
    if (scenarioType === 'SYNONYM') {
      this.quillRef.deleteText(start, end - start, 'user')
      this.quillRef.insertText(start, newSuggestion)
      this.quillRef.setSelection(start, newSuggestion.length - 2)
      window.scrollTo(0, top)
    } else {
      this.quillRef.insertText(end, newSuggestion)
      this.quillRef.setSelection(end + 1, newSuggestion.length - 2)
      window.scrollTo(0, top)
    }
  }

  static generateSuggestionSettings = (context: Context, currentWord: string) => {
    // get all words in front of the cursor
    let wordArray = context.prefix.split(' ')
    let sliceIndex: number
    // handle where to slice based on desire to get back arrays.length === 10 || less
    wordArray.length > 9 ? (sliceIndex = wordArray.length - 10) : (sliceIndex = 0)
    let scenarioType: string

    // determine where the cursor is and what type either return single word or word array
    context.suffix.split(' ')[0] === '' ? (scenarioType = 'GAP') : (scenarioType = 'SYNONYM')
    scenarioType === 'SYNONYM' ? (wordArray = [currentWord]) : (wordArray = wordArray.slice(sliceIndex))
    wordArray = wordArray.map((word) => word.replace(/[^\w\s]/gi, ''))
    // setup suggestionsObj
    const suggestionsSettings = {
      wordArray,
      scenarioType,
    }
    return suggestionsSettings
  }

  //TODO Switch to Cloud Run
  generateCurrentWordRange = (range: QuillTypes.RangeStatic, context: Context) => {
    const start = range.index - (last(context.prefix.split(' ')) || '').length
    const end = range.index + context.suffix.split(' ')[0].length
    const currentWordRange = {
      end,
      start,
      range,
    }
    return currentWordRange
  }

  //TODO Switch to Cloud Run
  handleQuickfix = async (range: QuillTypes.RangeStatic, context: Context) => {
    const currentWord = last(context.prefix.split(' ')) + context.suffix.split(' ')[0]

    const bounds = this.quillRef.getBounds(range)
    const { left, top, height } = bounds

    // get the suggestionSettings
    const suggestionsSettings = RawEditor.generateSuggestionSettings(context, currentWord)
    await this.props.setSuggestionsSettings(
      this.props.match.params.documentId,
      suggestionsSettings.scenarioType,
      suggestionsSettings.wordArray,
    )
    // hit ML graphQL happens in Redux
    pingMLServer(get(this.props, 'document.data.documentMeta.id'), 'lens')
    // ...pinging Optfit...
    // generate wordRange & setState for handleSuggestionInsert
    const currentWordRange = this.generateCurrentWordRange(range, context)
    this.setState({
      currentWordRange: {
        ...currentWordRange,
        scenarioType: suggestionsSettings.scenarioType,
      },
    })

    this.setState(() => ({
      range,
      tooltip: { x: left, y: top + height },
    }))
  }

  //TODO Switch to Cloud Run
  handleFirestoreUpdateDocument = () => {
    const { documentId } = this.props.match.params
    const { documentBody, range } = this.state
    this.props.updateDocument(documentId, documentBody)

    const top = get(document, 'documentElement.scrollTop')

    if (this.quillRef) {
      this.quillRef.setSelection(range)
      window.scrollTo(0, top)
    }
  }

  // autoSave = debounce(() => {
  //   this.handleFirestoreUpdateDocument()
  // }, 20000)

  handleUpdateTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState(() => ({ title: value }))
  }

  handleFirestoreUpdateTitle = () => {
    const { documentId } = this.props.match.params
    const { title } = this.state
    this.props.updateTitle(documentId, title || '')
  }

  handleToggleWrittingMode = () => {
    this.setState(() => {
      if (this.state.editorMode === EditorMode.Writing) {
        return { editorMode: EditorMode.Edit }
      }
      return { editorMode: EditorMode.Writing }
    })
  }

  handleChangeSelection = (range: QuillTypes.RangeStatic) => {
    this.setState(() => ({ range }))
  }

  getWordCount = () => {
    return this.quillRef
      .getText()
      .trim()
      .split(' ').length
  }

  cancelCrunchIt = () => {
    documentsRef.doc(get(this.props, 'document.data.documentMeta.id')).update({ mlJobRunning: false })
  }

  render() {
    const { className } = this.props

    if (this.props.document.loading || !this.props.document.data) {
      return <Loader text={`...Loading your document`} />
    }

    const { annotations } = this.props.document.data
    const { tooltip, editorMode, documentBody, wordCount } = this.state

    const suggestionsArray = get(this.props, 'document.data.suggestions', null)

    const saving = this.props.document.loading && this.props.document.data
    const showSidebar = editorMode === EditorMode.Edit

    const docTitle = this.state.title || 'draft'

    return (
      <EditorWrapper data-sidebar-open={showSidebar} className={className}>
        {this.props.document.data.mlJobRunning && (
          <AnimatedLoader>
            <Text>The robots are up to something...</Text>
            <CancelButton onClick={this.cancelCrunchIt}>Cancel</CancelButton>
          </AnimatedLoader>
        )}
        <EditorHeader>
          <Link to={'/'}>‹ All docs</Link>
          <DocumentTitle
            onBlur={this.handleFirestoreUpdateTitle}
            onChange={this.handleUpdateTitle}
            type="text"
            name="title"
            placeholder="draft"
            value={docTitle}
          />
          <DocumentTitleGhost>{docTitle}</DocumentTitleGhost>
          <Settings>
            <EditorSaveText style={{ opacity: saving ? 1 : 0.5 }}>{saving ? 'saving...' : 'saved'}</EditorSaveText>
            <Button onClick={this.handleToggleWrittingMode}>
              <i className="fas fa-ellipsis-h" />
            </Button>
          </Settings>
          <Sidebar document={this.props.document.data} documentBody={documentBody} show={showSidebar} documentTitle={docTitle} />
        </EditorHeader>
        <Content>
          <EditorContainer data-editor-disabled={this.props.document.data.mlJobRunning} data-editor-mode={editorMode === EditorMode.Edit}>
            <Gutters annotations={annotations} sidebarOpen={showSidebar} />
            {suggestionsArray && (
              <Tooltip onBlur={this.handleTooltipOnBlur} tooltip={tooltip}>
                <Suggestions onSelect={this.handleSuggestionInsert} suggestions={suggestionsArray} />
              </Tooltip>
            )}
            <ReactQuill
              ref={(el) => {
                this.reactQuillRef = el
              }}
              onChange={this.handleChange}
              value={documentBody}
              modules={this.modules}
              formats={this.formats}
              bounds={className}
              onChangeSelection={this.handleChangeSelection}
              placeholder={'Write something cool'}
            />
          </EditorContainer>
        </Content>
        {showSidebar && <Footer wordCount={wordCount} />}
      </EditorWrapper>
    )
  }
}

const Editor = styled(RawEditor)`
  will-change: width;
  transition: width 200ms;
  transform: translateZ(1);

  &[data-sidebar-open='true'] {
    width: calc(100% - ${sidebarWidth + RightGutterWidth}px);
  }

  ${Content} {
    position: relative;
  }

  ${DocumentTitle} {
    width: 50vw;
    text-overflow: ellipsis;
    background-color: transparent;
    outline: none;
    padding: 0.3rem;
    text-align: center;
    opacity: 0.7;
    transition: opacity 200ms;
    border: none;
    font-size: 1rem;
    color: var(--theme_primary-color);

    ::placeholder {
      font-style: ${FontStyle.Italic};
      opacity: 1;
    }

    :focus {
      opacity: 1;
    }

    :focus + ${DocumentTitleGhost} {
      border-color: var(--theme_primary-color);
    }
  }

  ${DocumentTitleGhost} {
    position: absolute;
    color: transparent;
    font-size: 1rem;
    pointer-events: none;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    transition: border-color 200ms, opacity 200ms;
    border-radius: 4px;
    padding: 0.3rem 1.5rem;
    text-align: center;
  }

  ${EditorHeader} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 40px;
    background: var(--theme_secondary-color);

    a {
      position: absolute;
      left: 0;
      text-decoration: none;
      padding: 0 1rem;
      opacity: 0.5;
      will-change: opacity;
      transition: opacity 200ms;

      :hover {
        opacity: 1;
      }
    }

    ${Settings} {
      position: absolute;
      right: 0;
      text-align: right;
      font-style: italic;

      ${EditorSaveText} {
        display: inline-block;
      }

      ${Button} {
        display: inline-block;
        padding: 0 1rem;
        opacity: 0.5;
        will-change: opacity;
        transition: opacity 200ms;

        i {
          color: var(--theme_primary-color);
        }
      }
    }

    :hover {
      ${Settings} {
        opacity: 1;
      }

      ${DocumentTitleGhost} {
        border-color: var(--theme_tertiary-color);
      }

      ${DocumentTitle} {
        opacity: 1;

        :focus {
          opacity: 1;
        }

        :focus + ${DocumentTitleGhost} {
          border-color: var(--theme_primary-color);
        }
      }
    }
  }

  ${EditorContainer} {
    position: relative;
    cursor: text;
    margin-top: 60px;
    padding-bottom: 5rem;
  }

  ${EditorContainer} [data-editor-disabled="true"] {
    pointer-events: none;
  }

  .quill {
    width: 100%;
  }

  .ql-container {
    border: 1px solid transparent;
    min-height: 18em;
  }

  .ql-editor {
    width: 100%;
    height: 100%;
    overflow-y: visible;
    padding: 0;
    margin: 0;
  }

  .ql-editor.ql-blank::before {
    color: var(--theme_primary-color);
    font-family: var(--theme_font);
    font-size: 1rem;
  }
  .ql-editor:focus {
    outline: none;
    border: none;
  }

  .ql-editor p {
    margin: 0 0 1em 0;
    will-change: opacity;
    opacity: 0.5;
    transition: opacity 150ms;
    position: relative;
    font-size: 1rem;
    line-height: 1.7em;
    font-family: var(--theme_font);
  }

  p.focused {
    opacity: 1;

    &:before {
      opacity: 1;
    }

    &:after {
      height: auto;
    }
  }

  [data-editor-mode='true'] {
    p {
      opacity: 1;

      &:before {
        opacity: 1;
      }

      &:after {
        height: auto;
      }
    }
  }
`

const EditorMap = ({ editor }: AppState) => ({ ...editor })

export default connect(
  EditorMap,
  { clearDocument, clearMlSuggestions, getDocument, setSuggestionsSettings, updateDocument, updateTitle },
)(Editor)
