import React, { Component } from 'react'
import { Styleable, AnyObject } from '../../types'
import styled from 'styled-components'

type Props = Styleable

function preventDefault(e: Event) {
  const event = e || window.event
  if (event.preventDefault) event.preventDefault()
  event.returnValue = false
}

const keys = { 37: 1, 38: 1, 39: 1, 40: 1 } as AnyObject

function preventDefaultForScrollKeys(e: KeyboardEvent) {
  if (keys[e.keyCode]) {
    preventDefault(e)
    return false
  }
}

function disableScroll() {
  if (window.addEventListener) {
    window.addEventListener('DOMMouseScroll', preventDefault, false)
  }
  window.onwheel = preventDefault // modern standard
  // window.ontouchmove = preventDefault // mobile
  document.onkeydown = preventDefaultForScrollKeys
}

function enableScroll() {
  if (window.removeEventListener) window.removeEventListener('DOMMouseScroll', preventDefault, false)
  window.onwheel = null
  // window.ontouchmove = null
  document.onkeydown = null
}

class RawAnimatedLoader extends Component<Props> {
  componentDidMount() {
    disableScroll()
  }
  componentWillUnmount() {
    enableScroll()
  }
  render() {
    return (
      <div className={this.props.className}>
        <div>
          <img src="http://samherbert.net/svg-loaders/svg-loaders/puff.svg" alt="" />
          {this.props.children}
        </div>
      </div>
    )
  }
}

export const AnimatedLoader = styled(RawAnimatedLoader)`
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;

  > div {
    display: flex;
    flex-flow: column;
    margin: auto;

    > * {
      margin: auto;
    }
  }

  :after {
    content: '';
    z-index: -1;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.85;
  }
`
