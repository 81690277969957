import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { AppState, Styleable, Auth } from '../types'
import { Link, RouteProps } from 'react-router-dom'
import { Routes } from '../constants'
import { deleteDocument, getDocuments } from '../store/documents/documents.actions'
import { Loader } from '../components/shared/loader.component'
import { ContentWrapper, Content } from '../components/shared/content.component'
import { darkGray, safetyOrange } from '../style/colors'
import { Heading, FontWeight } from '../style/typography'
import { Button } from '../style/button'
import { signOutRequest } from '../store/app/app.actions'

type DocumentsMapType = {
  auth: Auth
  documents: AppState['documents']
}

type ConnectedDispatchProps = {
  deleteDocument: (documentId: string) => void
  getDocuments: (documentId: string) => void
  signOutRequest: () => void
}

type Props = { auth: Auth } & ConnectedDispatchProps & DocumentsMapType & Styleable & RouteProps

const CreateDocumentButton = styled(Link)``
const SignOutButton = styled(Button)``

export class RawDocumentList extends React.Component<Props> {
  async componentDidMount() {
    await this.props.getDocuments(this.props.auth.uid)
  }

  handleDelete = (id: string) => {
    this.props.deleteDocument(id)
  }

  render() {
    const { documents, className } = this.props
    const { loading, data } = documents

    if ((loading && !data) || !data) {
      return <Loader />
    }
    return (
      <ContentWrapper className={className}>
        <Content>
          <div>
            <Heading weight={FontWeight.Bold} level={3}>
              Recent documents
            </Heading>
            <CreateDocumentButton to={Routes.CreateDocument}>Create a document</CreateDocumentButton>
            <SignOutButton onClick={this.props.signOutRequest}>Sign Out</SignOutButton>
          </div>
          <ul>
            {data.map((doc) => (
              <li key={doc.id}>
                <Link to={`${Routes.Editor.replace(':documentId', doc.id)}`}>{doc.title || 'draft'}</Link>
                <Button onClick={() => this.handleDelete(doc.id)}>Delete</Button>
              </li>
            ))}
          </ul>
        </Content>
      </ContentWrapper>
    )
  }
}

const DocumentsList = styled(RawDocumentList)`
  padding-top: 2rem;

  ${Content} {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ${Heading} {
        margin: 0;
      }
    }
  }
  ${SignOutButton} {
    border: 2px solid white;
    border-color: var(--theme_primary-color);
    color: var(--theme_primary-color);

    padding: 0.5rem;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
  }

  ${CreateDocumentButton} {
    border: 2px solid white;
    border-color: var(--theme_primary-color);
    color: var(--theme_primary-color);

    padding: 0.5rem;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
  }

  ul {
    width: 100%;
    margin-top: 2rem;

    li {
      list-style-type: none;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid ${darkGray};

      :last-child {
        border: none;
      }

      ${Button} {
        color: var(--theme_primary-color);
        opacity: 0;
        cursor: pointer;
        z-index: 1;
        margin-left: 1rem;

        :hover {
          color: ${safetyOrange};
        }
      }

      :hover {
        background: ${darkGray};

        ${Button} {
          opacity: 1;
        }
      }

      a {
        padding: 1rem 0.5rem;
        flex-grow: 1;
        text-decoration: none;
      }
    }
  }
`

const DocumentsMap = ({ app: { auth }, documents }: AppState) => ({ auth, documents })

export default connect(
  DocumentsMap,
  { deleteDocument, getDocuments, signOutRequest },
)(DocumentsList)
