import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import monitorReducersEnhancer from './enhancers/enhancers'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import rootReducer from './reducers'

export const history = createBrowserHistory()

// TODO figure out this any
export default function configureStore(preloadedState?: any) {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)
  const store = createStore(connectRouter(history)(rootReducer), preloadedState, composedEnhancers)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(require('./reducers').default)
    })
  }

  return store
}
