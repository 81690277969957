import { client } from './client'
import gql from 'graphql-tag'

//TODO Switch to Cloud Run
export const pingMLServer = async (docId: string, ptype: string) => {
  await client
    .query({
      query: gql`
        query($docId: String!, $ptype: String!) {
          docById(docId: $docId, ptype: $ptype)
        }
      `,
      variables: { docId, ptype },
      fetchPolicy: 'network-only',
    })
    .then((data) => {
      return data
    })
    .catch((error) => console.error(error))
}
