import React, { Component, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Styleable, DocumentSettings, AppState, Auth } from '../types'
import { connect } from 'react-redux'
import { createDocument } from '../store/documents/documents.actions'
import { ContentWrapper, Content } from '../components/shared/content.component'
import CreateDocumentSelections from '../components/CreateDocumentSelections'

type ConnectedDispatchProps = {
  createDocument: (partialDocument: DocumentSettings, uid: string) => void
}

type Props = { auth: Auth } & ConnectedDispatchProps & Styleable
type State = { lens: string; style: string }

const LENSES: Array<string> = ['GOTHIC', 'SHAKESPEARE', 'JOURNALISM', 'HEMINGWAY', 'CUSTOM', 'MORE COMING SOON']
const STYLES: Array<string> = ['FICTION', 'NONFICTION', 'POETRY', 'ESSAY', 'SCREENPLAY', 'MORE COMING SOON']

class RawCreateDocument extends Component<Props, State> {
  state = {
    lens: '',
    style: '',
  }

  handleCreateDocument = () => {
    const newDocumentData = { ...this.state }
    this.props.createDocument(newDocumentData, this.props.auth.uid)
  }

  handleLensSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    this.setState(() => ({ lens: value }))
  }

  handleStyleSelection = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    await this.setState(() => ({ style: value }))
    this.handleCreateDocument()
  }

  render() {
    const { lens, style } = this.state
    return (
      <ContentWrapper className={this.props.className}>
        <Content>
          {lens === '' && (
            <CreateDocumentSelections
              handleSelection={this.handleLensSelection}
              selectionOptions={LENSES}
              selected={lens}
              title="Select your lens"
            />
          )}
          {lens !== '' && (
            <CreateDocumentSelections
              handleSelection={this.handleStyleSelection}
              selectionOptions={STYLES}
              selected={style}
              title="Select your style"
            />
          )}
        </Content>
      </ContentWrapper>
    )
  }
}

const CreateDocument = styled(RawCreateDocument)``
const AppMap = ({ app }: AppState) => ({ auth: app.auth })

export default connect(
  AppMap,
  { createDocument },
)(CreateDocument)
