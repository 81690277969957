import { documentsRef } from '../../firebase/firebase'
import { DocumentSettings, DialogueDocument, DocumentSummary } from '../../types'
import { Action, ActionCreator, Dispatch } from 'redux'
// import { times, random } from 'lodash-es'

import { history } from '../configureStore'
import { Routes } from '../../constants'

// CREATE DOCUMENT REQUEST
export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST'
export const createDocumentRequest: ActionCreator<Action> = () => ({
  type: CREATE_DOCUMENT_REQUEST,
})

// CREATE DOCUMENT SUCCESS
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS'
const createDocumentSuccess: ActionCreator<Action> = (documentId: string) => {
  return {
    type: CREATE_DOCUMENT_SUCCESS,
    payload: documentId,
  }
}

// CREATE DOCUMENT ERROR
export const CREATE_DOCUMENT_ERROR = 'CREATE_DOCUMENT_ERROR'
const createDocumentError: ActionCreator<Action> = (error: Error) => {
  return {
    type: CREATE_DOCUMENT_ERROR,
    payload: error,
  }
}

const DocumentModel = (newDocumentId: string, settings: { lens: string; style: string }, ownerId: string) => ({
  ownerId,
  annotations: [],
  docBody: '',
  documentMeta: { title: '', id: newDocumentId, settings: { ...settings } },
  mlJobRunning: false,
  suggestionSettings: null,
  suggestions: null,
})

// const generateFakeAnnotations = times(20, () => ({
//   sentiment: random(0, 10),
//   targetLength: random

// (200, 1200),
//   targetSentiment: random(0, 10),
// }))

// CREATE DOCUMENT THUNK
export const createDocument = (docSettings: DocumentSettings, ownerId: string) => async (dispatch: Dispatch<any>) => {
  dispatch(createDocumentRequest())
  // GENERATES NEW DOCUMENT ID
  const newDocumentRef = documentsRef.doc()
  // TODO: gut this when we have functional annotations
  // const annotations = generateFakeAnnotations
  const newDocument = DocumentModel(newDocumentRef.id, docSettings, ownerId)
  try {
    await documentsRef.doc(newDocumentRef.id).set(newDocument)
    await dispatch(createDocumentSuccess(newDocumentRef.id))
    history.push(Routes.Editor.replace(':documentId', newDocumentRef.id))
  } catch (err) {
    dispatch(createDocumentError(err))
  }
}

// DELETE DOCUMENT REQUEST
export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST'
const deleteDocumentRequest = () => ({
  type: DELETE_DOCUMENT_REQUEST,
})

// DELETE DOCUMENT SUCCESS
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
const deleteDocumentSuccess = (documentId: string) => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: documentId,
})

// DELETE DOCUMENT ERROR
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR'
const deleteDocumentError = (error: Error) => ({
  type: DELETE_DOCUMENT_ERROR,
  payload: error,
})

// DELETE DOCUMENT THUNK
export const deleteDocument = (documentId: string) => (dispatch: Dispatch) => {
  dispatch(deleteDocumentRequest())
  documentsRef
    .doc(documentId)
    .delete()
    .then(() => {
      dispatch(deleteDocumentSuccess(documentId))
    })
    .catch((error) => {
      dispatch(deleteDocumentError(error))
    })
}

export const GET_ALL_DOCUMENTS_SUCCESS = 'GET_ALL_DOCUMENTS_SUCCESS'
const getDocumentsSuccess: ActionCreator<Action> = (documents: Array<DialogueDocument>) => {
  return { type: GET_ALL_DOCUMENTS_SUCCESS, payload: documents }
}

export const getDocuments = (uid: string) => async (dispatch: Dispatch<any>) => {
  // TODO: get only the fields we need to list
  await documentsRef.where('ownerId', '==', `${uid}`).onSnapshot((snapshot) => {
    const documents: Array<DocumentSummary> = []
    snapshot.forEach((doc) => {
      const { documentMeta } = doc.data()
      documents.push({
        id: doc.id,
        title: documentMeta.title,
      })
    })
    dispatch(getDocumentsSuccess(documents))
  })
  // TODO handle this error
  // .catch((err: Error) => {
  //   dispatch(getDocumentsError(err))
  // })
}
