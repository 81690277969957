import { combineReducers } from 'redux'
import documentsReducer from './documents/documents.reducer'
import editorReducer from './editor/editor.reducer'
import appReducer from './app/app.reducer'

const rootReducer = combineReducers({
  app: appReducer,
  documents: documentsReducer,
  editor: editorReducer,
})

export default rootReducer
