export const black = '#000'
export const white = '#FFF'
export const darkGray = '#323232'
export const ebony = '#0D101E'
export const safetyOrange = '#FF6100'
export const cinder = '#0A0C17'
export const thunder = '#4B4B4B'
export const mirage = '#131B21'
export const raven = '#71767A'
export const blackWhite = '#FFFEF6'

export const DARK_THEME = { colors: [white, ebony, raven], font: '"Space Mono", monospace;' }

export const LIGHT_THEME = { colors: [ebony, blackWhite, darkGray], font: '"Space Mono", monospace;' }
