import styled from 'styled-components'
import { HTMLProps } from 'react'
import { mobile } from './breakpoints'
import { propOr } from '../utils/logic.utils'
import { property } from 'lodash-es'

export enum FontWeight {
  Light = '200',
  Regular = '400',
  Semibold = '500',
  Bold = '600',
  Heavy = '700',
  ExtraHeavy = '800',
  Inherit = 'inherit',
}

export enum FontStyle {
  Normal = 'normal',
  Italic = 'italic',
  Oblique = 'oblique',
  Inherit = 'inherit',
}

export type FontSizes = {
  [key: string]: string
  level1: string
  level2: string
  level3: string
  level4: string
  level5: string
  level6: string
}

export type FontMetrics = {
  size: {
    [key: string]: FontSizes
    mobile: FontSizes
    desktop: FontSizes
  }
}

export const fontStack = '"Space Mono", mono'

const deriveFontSizes = (base: number, sizes: Array<number>) =>
  sizes.reduce((acc: { [key: string]: string }, size: number, idx: number) => {
    acc[`level${idx + 1}`] = `${size / base}rem`

    return acc
  }, {}) as FontSizes

export const fontMetrics: FontMetrics = {
  size: {
    mobile: deriveFontSizes(16, [28, 23, 19, 16, 13, 11]),
    desktop: deriveFontSizes(18, [48, 40, 24, 20, 16, 14]),
  },
}

export type TextProps = {
  weight?: FontWeight
  color?: string
  level?: number
  fontStyle?: FontStyle
}

export const getFontSize = (layout: string) => ({ level }: TextProps): string => fontMetrics.size[layout][`level${level}`] || 'inherit'

export const Text = styled<TextProps & HTMLProps<HTMLParagraphElement>, 'p'>('p')`
  font-weight: ${propOr('weight', FontWeight.Regular)};
  font-size: ${getFontSize('mobile') as any};
  font-style: ${propOr('fontStyle', FontStyle.Normal)};
  line-height: 1.2;
  margin: 0;

  @media (min-width: ${mobile}px) {
    font-size: ${getFontSize('desktop') as any};
  }
`
Text.defaultProps = {
  level: 4,
}
Text.displayName = 'Text'

export const Heading = styled<HTMLProps<HTMLDivElement> & TextProps, 'div'>('div').attrs({
  role: 'heading',
  'aria-level': property('level') as () => number,
})`
  font-weight: ${propOr('weight', FontWeight.Regular)};
  font-size: ${getFontSize('mobile') as any};
  font-style: ${propOr('fontStyle', FontStyle.Normal)};
  line-height: 1.2;
  margin-bottom: 1rem;

  @media (min-width: ${mobile}px) {
    font-size: ${getFontSize('desktop') as any};
  }
`
Heading.defaultProps = {
  level: 1,
}
Heading.displayName = 'Heading'
