import { ActionCreator, Action, Dispatch } from 'redux'
import { DialogueDocument, Annotation } from '../../types'
import { documentsRef } from '../../firebase/firebase'
import { get, round } from 'lodash-es'

const parseAnnotations = (annotations: Array<Annotation>) =>
  annotations.map((annotation) => ({
    sentiment: round(get(annotation, 'sentiment', 0) * 10, 1),
    targetLength: round(get(annotation, 'targetLength', 0) * 10, 1),
    targetSentiment: round(get(annotation, 'targetSentiment', 0) * 10, 1),
  }))

export const SET_ML_DOCUMENT_UPDATES = 'SET_ML_DOCUMENT_UPDATES'
const setMlDocumentUpdates = (updatedDocument: DialogueDocument) => ({
  type: SET_ML_DOCUMENT_UPDATES,
  payload: updatedDocument,
})

export const SET_ML_JOB_RUNNING = 'SET_ML_JOB_RUNNING'
const setMlJobRunning = (mlJobRunning: boolean) => ({
  type: SET_ML_JOB_RUNNING,
  payload: mlJobRunning,
})

// LISTEN FOR DOCUMENT ANNOTATION CHANGES
export const listenForMlDocumentChanges = (dialogueId: string) => async (dispatch: Dispatch<any>) => {
  await documentsRef.doc(dialogueId).onSnapshot((doc) => {
    const document = doc.data() as DialogueDocument
    if (document && document.mlJobRunning !== true) {
      const roundedAnnotations = parseAnnotations(get(document, 'annotations', []))
      const parsedAnnotationsDocument = { ...document, annotations: roundedAnnotations }
      dispatch(setMlDocumentUpdates(parsedAnnotationsDocument))
    }
    if (document.mlJobRunning === true) {
      const mlJobRunning = true
      dispatch(setMlJobRunning(mlJobRunning))
    }
  })
}

// UPDATE TITLE REQUEST
export const UPDATE_TITLE_REQUEST = 'UPDATE_TITLE_REQUEST'
const updateTitleRequest: ActionCreator<Action> = () => {
  return { type: UPDATE_TITLE_REQUEST }
}
// UPDATE TITLE SUCCESS
export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS'
const updateTitleSuccess: ActionCreator<Action> = (updatedTitle: string) => {
  return {
    type: UPDATE_TITLE_SUCCESS,
    payload: updatedTitle,
  }
}

// UPDATE TITLE ERROR
export const UPDATE_TITLE_ERROR = 'UPDATE_TITLE_ERROR'
const updateTitleError: ActionCreator<Action> = (err: Error) => {
  return {
    type: UPDATE_TITLE_ERROR,
    payload: err,
  }
}

// UPDATE TITLE THUNK
export const updateTitle = (documentId: string, updatedTitle: string) => async (dispatch: Dispatch<any>) => {
  dispatch(updateTitleRequest())
  try {
    documentsRef.doc(documentId).update({ 'documentMeta.title': `${updatedTitle}` })
    dispatch(updateTitleSuccess(updatedTitle))
  } catch (err) {
    dispatch(updateTitleError(err))
  }
}

// UPDATE DOCUMENT REQUEST
export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST'
const updateDocumentRequest: ActionCreator<Action> = () => {
  return { type: UPDATE_DOCUMENT_REQUEST }
}
// UPDATE DOCUMENT SUCCESS
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS'
const updateDocumentSuccess: ActionCreator<Action> = () => {
  return {
    type: UPDATE_DOCUMENT_SUCCESS,
  }
}

// UPDATE DOCUMENT ERROR
export const UPDATE_DOCUMENT_ERROR = 'UPDATE_DOCUMENT_ERROR'
const updateDocumentError: ActionCreator<Action> = (err: Error) => {
  return {
    type: UPDATE_DOCUMENT_ERROR,
    payload: err,
  }
}

// UPDATE DOCUMENT THUNK
export const updateDocument = (documentId: string, docBody: string) => async (dispatch: Dispatch<any>) => {
  dispatch(updateDocumentRequest())
  try {
    documentsRef.doc(documentId).update({ docBody })
    dispatch(updateDocumentSuccess())
  } catch (err) {
    dispatch(updateDocumentError(err))
  }
}

// GET DOCUMENT REQUEST
export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST'
const getDocumentRequest: ActionCreator<Action> = () => {
  return { type: GET_DOCUMENT_REQUEST }
}

// GET DOCUMENT SUCCESS
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS'
const getDocumentSucess: ActionCreator<Action> = (doc: DialogueDocument) => {
  return { type: GET_DOCUMENT_SUCCESS, payload: doc }
}

// GET DOCUMENT ERROR
export const GET_DOCUMENT_ERROR = 'GET_DOCUMENT_ERROR'
const getDocumentError: ActionCreator<Action> = (err: Error) => {
  console.log(err)
  return {
    type: GET_DOCUMENT_ERROR,
    payload: err,
  }
}

// GET DOCUMENT THUNK
export const getDocument = (documentId: string) => async (dispatch: Dispatch<any>) => {
  dispatch(getDocumentRequest())
  await documentsRef
    .doc(documentId)
    .get()
    .then((documentSnapshot) => {
      const document = documentSnapshot.data()
      const roundedAnnotations = parseAnnotations(get(document, 'annotations', []))
      const parsedAnnotationsDocument = { ...document, annotations: roundedAnnotations }
      dispatch(getDocumentSucess(parsedAnnotationsDocument))
      dispatch(listenForMlDocumentChanges(documentId))
    })
    .catch((err) => {
      dispatch(getDocumentError(err))
    })
}

// CLEAR DOCUMENT
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT'
const clearDocumentSuccess: ActionCreator<Action> = () => {
  return { type: CLEAR_DOCUMENT }
}

// CLEAR DOCUMENT THUNK
export const clearDocument = (documentId: string) => async (dispatch: Dispatch<any>) => {
  unsubscribeMlUpdatesListener(documentId)
  dispatch(clearDocumentSuccess())
}

// SET SUGGESTIONS SETTINGS REQUEST
export const SET_SUGGESTIONS_SETTINGS_REQUEST = 'SET_SUGGESTIONS_SETTINGS_REQUEST'
const setSuggestionsSettignsRequest = () => ({
  type: SET_SUGGESTIONS_SETTINGS_REQUEST,
})

// SET SUGGESTIONS SETTINGS SUCCESS
export const SET_SUGGESTIONS_SETTINGS_SUCCESS = 'SET_SUGGESTIONS_SETTINGS_SUCCESS'
const setSuggestionsSettingsSuccess = () => ({
  type: SET_SUGGESTIONS_SETTINGS_SUCCESS,
})

// SET SUGGESTIONS SETTINGS ERROR
export const SET_SUGGESTIONS_SETTINGS_ERROR = 'SET_SUGGESTIONS_SETTINGS_ERROR'
const setSuggestionsSettingsError: ActionCreator<Action> = (err: Error) => ({
  type: SET_SUGGESTIONS_SETTINGS_ERROR,
  error: err,
})

// SET SUGGESTIONS SETTINGS THUNK
export const setSuggestionsSettings = (documentId: string, scenarioType: string, wordArray: Array<string>) => async (
  dispatch: Dispatch<any>,
) => {
  dispatch(setSuggestionsSettignsRequest())
  try {
    await documentsRef.doc(documentId).update({
      mlJobRunning: true,
      suggestionSettings: {
        scenarioType,
        wordArray,
      },
      suggestions: [],
    })
    dispatch(setSuggestionsSettingsSuccess())
  } catch (err) {
    dispatch(setSuggestionsSettingsError(err))
  }
}

export const CLEAR_ML_SUGGESTIONS_REQUEST = `CLEAR_ML_SUGGESTIONS_REQUEST`
const clearMlSuggestionsRequest = () => ({
  type: CLEAR_ML_SUGGESTIONS_REQUEST,
})

export const CLEAR_ML_SUGGESTIONS_SUCCESS = `CLEAR_ML_SUGGESTIONS_SUCCESS`
const clearMlSuggestionsSuccess = () => ({
  type: CLEAR_ML_SUGGESTIONS_SUCCESS,
})
export const CLEAR_ML_SUGGESTIONS_ERROR = `CLEAR_ML_SUGGESTIONS_ERROR`

// CLEAR ML SUGGESTIONS IN FIREBASE
export const clearMlSuggestions = (documentId: string) => async (dispatch: Dispatch<any>) => {
  dispatch(clearMlSuggestionsRequest())
  dispatch(clearMlSuggestionsSuccess())
  // try
  try {
    await documentsRef.doc(documentId).update({
      suggestionSettings: {},
      suggestions: [],
    })
  } catch (error) {
    // success
    alert('sss')
  } // error
}

// UNSUBSCRIBE TO LISTENER FUNCTION
const unsubscribeMlUpdatesListener = (documentId: string) => {
  documentsRef.doc(documentId).onSnapshot(() => {})
}
