import { ActionCreator, Action, Dispatch } from 'redux'
import { auth, facebookAuthProvider, googleAuthProvider } from '../../firebase/firebase'
import { AUTH_STATUS, Routes } from '../../constants'
import { AppThemeName, Auth } from '../../types'
import { LIGHT_THEME, DARK_THEME } from '../../style/colors'
import { history } from '../configureStore'
import { get } from 'lodash-es'

export const SET_APP_THEME = 'SET_APP_THEME'
export const setAppTheme: ActionCreator<Action> = (themeName: AppThemeName) => {
  const theme = themeName === AppThemeName.Light ? LIGHT_THEME : DARK_THEME
  localStorage.setItem('theme', JSON.stringify(theme))

  return {
    type: SET_APP_THEME,
    payload: theme,
  }
}

const signedIn = (user: Auth) => ({
  type: AUTH_STATUS.SIGNED_IN,
  payload: user,
})

const signedOut = () => ({
  type: AUTH_STATUS.SIGNED_OUT,
})

// NOTE: Commented out Gmail auth, FB auth w/ popup
export var signInRequest = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: AUTH_STATUS.ATTEMPTING_CHANGE })
  auth.signInWithPopup(googleAuthProvider).then(() => {
    history.push(Routes.DocumentList)
  })
}
// FIX: When Gmail + FB auth are used, two popups show
// TO-DO: Add FB and Google login buttons
export const facebookSignInRequest = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: AUTH_STATUS.ATTEMPTING_CHANGE })
  auth.signInWithPopup(facebookAuthProvider).then(() => {
    history.push(Routes.DocumentList)
  })
}

export const signOutRequest = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: AUTH_STATUS.ATTEMPTING_CHANGE })
  auth.signOut()
}

export const listenForAuthChanges = () => (dispatch: Dispatch<any>) => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      const userData = {
        uid: user.uid,
        photoURL: user.photoURL,
        email: get(user, 'email'),
        displayName: user.displayName,
        status: AUTH_STATUS.SIGNED_IN,
        loading: false,
      }
      dispatch(signedIn(userData))
    } else {
      dispatch(signedOut())
    }
  })
}
