import React from 'react'
import ReactGA from 'react-ga'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import { injectGlobal } from 'styled-components'
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { history } from './store/configureStore'
import globalStyles from './style/globals'
import App from './containers/App'
import { listenForAuthChanges } from './store/app/app.actions'

export const store = configureStore()
injectGlobal`${globalStyles}`

store.dispatch(listenForAuthChanges())

function fireTracking() {
  ReactGA.pageview(window.location.hash)
}

// TODO: Remove this as soon as this issue is fixed: https://github.com/parcel-bundler/parcel/issues/1317
if ((module as any).hot) {
  ;(module as any).hot.accept(() => {
    location.reload()
  })
}

if (process.env.NODE_ENV === 'production') {
  console.log('Hello from Dialogues!')
  ReactGA.initialize('UA-128641238-3')
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route onUpdate={fireTracking} component={App} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
)
