import React, { Component } from 'react'
import styled from 'styled-components'

import { Styleable, Annotation } from '../../types'
import { globalSpacing } from './content.component'
import { get } from 'lodash-es'
import { raven, white } from '../../style/colors'

type Props = {
  annotations: Array<Annotation>
  sidebarOpen: boolean
} & Styleable

export const RightGutterWidth = 250
const Notation = styled.div``
const HoverTooltip = styled.div``

type IndicatorProps = {
  indicator: number
}
const Indicator = styled.span`
  width: 3px;
  max-width: 3px;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 250ms;
  background: ${({ indicator }: IndicatorProps) => (indicator < 0.5 ? 'red' : 'green')};
`
const LeftIndicator = styled(Indicator)`
  left: -${globalSpacing}px;
`
const RightIndicator = styled(Indicator)`
  right: ${RightGutterWidth - globalSpacing}px;
`
const GutterContainer = styled.div``

const getStyle = (index: number) => {
  const element = document.querySelector(`.ql-editor p:nth-of-type(${index + 1})`)
  if (element) {
    const { height, top } = element.getBoundingClientRect()
    const topOffset = top + get(document, 'documentElement.scrollTop')
    return { height: height + 'px', top: topOffset - 60 + 'px' }
  }

  return null
}

export class RawGutters extends Component<Props> {
  render() {
    const { className, annotations, sidebarOpen } = this.props

    const elements = document.querySelectorAll('.ql-editor p').length
    return (
      <GutterContainer className={className}>
        {annotations.map((notation, index) => {
          if (index > elements) {
            return null
          }
          const { sentiment } = notation
          let emoji = '👀'
          if (sentiment <= 2.9) {
            emoji = '😢'
          } else if (sentiment > 2.9 && sentiment <= 4.4) {
            emoji = '🙁'
          } else if (sentiment > 4.4 && sentiment <= 5.5) {
            emoji = '😐'
          } else if (sentiment > 5.5 && sentiment <= 7.0) {
            emoji = '🙂'
          } else {
            emoji = '😃'
          }
          return (
            <Notation data-show={sidebarOpen} style={{ ...getStyle(index) }} key={index}>
              <LeftIndicator indicator={notation.sentiment} />
              <RightIndicator indicator={notation.targetSentiment} />
              <HoverTooltip>
                {!isNaN(notation.sentiment) && (
                  <div>
                    <strong>
                      {`sentiment: `}
                      <span>{emoji}</span>
                      {`(${notation.sentiment})`}
                    </strong>
                  </div>
                )}
                {!isNaN(notation.targetSentiment) && (
                  <div>
                    <strong>{`target sentiment: ${notation.targetSentiment}`} </strong>
                  </div>
                )}
                {!isNaN(notation.targetLength) && (
                  <div>
                    <strong>{`target length: ${notation.targetLength}`} </strong>
                  </div>
                )}
              </HoverTooltip>
            </Notation>
          )
        })}
      </GutterContainer>
    )
  }
}

export const Gutters = styled(RawGutters)`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + ${RightGutterWidth}px);
  height: 100%;

  ${Notation} {
    width: 100%;
    position: absolute;

    ${HoverTooltip} {
      display: none;
      color: ${white};
      position: absolute;
      bottom: 0;
      left: calc(100% - ${RightGutterWidth - globalSpacing - 10}px);
      background: ${raven};
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 0.8rem;
    }

    &:hover,
    &[data-show='true'] {
      ${LeftIndicator}, ${RightIndicator} {
        opacity: 1;
      }

      ${HoverTooltip} {
        display: inline-block;
      }
    }
  }
`
