const firebaseConfig = {
  apiKey: 'AIzaSyCv10TPfY8f1fVe7JgJDON4j3G-ISK8zww',
  authDomain: 'dialogues-dev.firebaseapp.com',
  databaseURL: 'https://dialogues-dev.firebaseio.com',
  projectId: 'dialogues-dev',
  storageBucket: 'dialogues-dev.appspot.com',
  messagingSenderId: '848979806728',
}

export default firebaseConfig
