import React, { Component, HTMLProps, createRef } from 'react'
import Downshift from 'downshift'

import { Suggestion, Styleable } from '../../types'
import styled from 'styled-components'
import { black, darkGray } from '../../style/colors'

type Props = {
  suggestions: Array<Suggestion>
  onSelect: (suggestion: string) => void
} & Styleable

type State = {
  value: string
}

const TooltipSuggestionSpan = styled<HTMLProps<HTMLSpanElement>, 'span'>('span')`
  flex-grow: 1;
  margin-right: 1rem;
`

const TooltipConfidenceSpan = styled<{ colorValue: number } & HTMLProps<HTMLSpanElement>, 'span'>('span')`
  padding-left: '1rem';
  color: ${({ colorValue }) => (colorValue > 0.5 ? `rgb(0, ${colorValue * 255}, 0)` : `rgb(${colorValue * 255}, 0 , 0)`)};
`

class RawSuggestions extends Component<Props, State> {
  private inputRef = createRef<HTMLInputElement>()

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  render() {
    const { suggestions, className } = this.props

    if (suggestions === []) {
      return <img src="http://samherbert.net/svg-loaders/svg-loaders/puff.svg" alt="" />
    }
    // This check is because the ML server returns null values as NaN
    // if (typeof suggestions[0].text !== 'string') {
    //   return <h1>Sorry, we couldn't find any suggestions</h1>
    // }

    //TODO Switch to Cloud Run
    return (
      <Downshift onChange={(selection) => this.props.onSelect(selection.text)} itemToString={(item) => (item ? item.text : '')}>
        {({ getItemProps, getInputProps, inputValue, highlightedIndex, selectedItem }) => (
          <div className={className}>
            <input ref={this.inputRef} {...getInputProps()} />
            {suggestions.filter((suggestions) => !inputValue || suggestions.text.includes(inputValue)).map((item, index) => (
              <div
                {...getItemProps({
                  index,
                  item,
                  key: item.text,
                  style: {
                    backgroundColor: highlightedIndex === index ? black : darkGray,
                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                    display: 'flex',
                  },
                })}
              >
                <TooltipSuggestionSpan>{item.text}</TooltipSuggestionSpan>
                <TooltipConfidenceSpan colorValue={item.confidence}>{` ${Math.floor(item.confidence * 100)}%`}</TooltipConfidenceSpan>
              </div>
            ))}
          </div>
        )}
      </Downshift>
    )
  }
}

export const Suggestions = styled(RawSuggestions)`
  input {
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  div {
    background: ${darkGray};
    padding: 0.5rem 1.5rem;

    &:hover {
      background: black;
      cursor: pointer;
    }
  }
`
