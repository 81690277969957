import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import firebaseConfig from './firebaseConfig'

firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore()
const settings = { timestampsInSnapshots: true }
firestore.settings(settings)

export default firebase
export const auth = firebase.auth()
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
// NOTE: FB AUTH ADDED
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
export const documentsRef = firestore.collection('testDocuments')
// HAVE TO CALL FIREBASE AGAIN IN THE UPDATE ARRAY CASE
export const updateArray = firebase.firestore.FieldValue
export const Timestamp = firebase.firestore.FieldValue.serverTimestamp()
