import React, { SFC, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Heading } from '../style/typography'
import { black, thunder, white } from '../style/colors'
import { Styleable } from '../types'

const SelectionWrapper = styled.div``
const SelectionLabel = styled.label``
const SelectionInput = styled.input``

type Props = {
  handleSelection: (e: ChangeEvent<HTMLInputElement>) => void
  selectionOptions: Array<string>
  selected: string
  title: string
} & Styleable

const RawCreateDocumentSelections: SFC<Props> = ({ handleSelection, selectionOptions, selected, title, className }) => {
  return (
    <SelectionWrapper className={className}>
      <Heading>{title}</Heading>
      <div>
        {selectionOptions.map((lens: string) => (
          <SelectionLabel key={lens}>
            <SelectionInput type="radio" name="lens" value={lens} checked={selected === lens} onChange={handleSelection} />
            {lens}
          </SelectionLabel>
        ))}
      </div>
    </SelectionWrapper>
  )
}
const CreateDocumentSelections = styled(RawCreateDocumentSelections)`
  > div {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8%;
  }

  ${SelectionLabel} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8em;
    width: 31%;
    background: ${thunder};
    margin: 1.1%;
    border-radius: 4px;
    color: ${white};
    cursor: pointer;

    :hover {
      color: ${black};
      background: ${white};
      -webkit-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
      -moz-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
    }
    :last-child {
      color: ${thunder};
      border: 3px solid ${thunder};
      :hover {
        box-shadow: none;
        color: ${thunder};
        border: ${thunder}, 3px, solid;
      }
    }
  }

  ${SelectionInput} {
    appearance: none;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
`

export default CreateDocumentSelections
