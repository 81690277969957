import { AppTheme, Auth } from '../../types'
import { AUTH_STATUS } from '../../constants'
import { SET_APP_THEME } from './app.actions'
import { LIGHT_THEME } from '../../style/colors'
import { AnyAction } from 'redux'

type AppState = {
  auth: Auth
  theme: AppTheme
}

const currentTheme = localStorage.getItem('theme')

let theme

if (currentTheme) {
  theme = JSON.parse(currentTheme)
}

if (!currentTheme) {
  localStorage.setItem('theme', JSON.stringify(LIGHT_THEME))
  theme = LIGHT_THEME
}

const initialState: AppState = {
  theme,
  auth: {
    status: AUTH_STATUS.ANONYMOUS,
    uid: '',
    email: '',
    loading: true,
  },
}

export default function documentReducer(state = initialState, action: AnyAction) {
  const { payload, type } = action

  switch (type) {
    case SET_APP_THEME:
      return { ...state, theme: payload }
    case AUTH_STATUS.SIGNED_IN: {
      return { ...state, auth: { status: AUTH_STATUS.SIGNED_IN, ...payload } }
    }
    case AUTH_STATUS.SIGNED_OUT: {
      return { ...state, auth: { ...initialState.auth, loading: false } }
    }
    case AUTH_STATUS.ATTEMPTING_CHANGE: {
      return { ...state, auth: { loading: true } }
    }
    default:
      return state
  }
}
