import React, { SFC } from 'react'
import { connect } from 'react-redux'
import { signInRequest, facebookSignInRequest } from '../store/app/app.actions'
import { Auth, AppState } from '../types'
import Login from '../components/Login'

type Props = {
  auth: Auth
  signInRequest: () => void
  facebookSignInRequest: () => void
}

const LoginContainer: SFC<Props> = (props) => <Login {...props} />

const AppMap = ({ app: { auth } }: AppState) => ({ auth })

export default connect(
  AppMap,
  { signInRequest, facebookSignInRequest },
)(LoginContainer)
