import React, { SFC } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Route, Switch, Redirect, RouteProps } from 'react-router-dom'
import { get } from 'lodash-es'
import { AUTH_STATUS, Routes } from '../constants'
import { Auth, Styleable, AppState, AppTheme } from '../types'
import CreateDocument from './CreateDocument'
import LoginContainer from './LoginContainer'
import DocumentList from './DocumentList'
import Editor from '../components/editor'
import { Text } from '../style/typography'
import { white } from '../style/colors'
import { AnimatedLoader } from '../components/shared/animated_loader.component'

type AppMapType = {
  auth: Auth
  theme: AppTheme
}

type Props = AppMapType & Styleable

type PrivateRouteProps = RouteProps & {
  isAuthenticated: boolean
}

const PrivateRoute: SFC<PrivateRouteProps> = ({ isAuthenticated, ...rest }) => {
  return isAuthenticated ? <Route {...rest} /> : <Redirect to={Routes.Login} />
}

export class RawApp extends React.Component<Props, {}> {
  render() {
    const { auth, className } = this.props
    const isAuthenticated = auth && auth.status === AUTH_STATUS.SIGNED_IN

    if (auth && auth.loading === true) {
      return (
        <AnimatedLoader>
          <Text color={white}>The robots are loggin you in...</Text>
        </AnimatedLoader>
      )
    }

    return (
      <div className={className}>
        <main>
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            render={() => (
              <Switch>
                <Route exact path={Routes.CreateDocument} component={CreateDocument} />
                <Route exact path={Routes.Editor} component={Editor} />
                <Route exact path={Routes.DocumentList} component={DocumentList} />
                <Redirect to={Routes.DocumentList} />
              </Switch>
            )}
          />
          <Route exact path={Routes.Login} component={LoginContainer} />
        </main>
      </div>
    )
  }
}

const App = styled(RawApp)`
  --theme_primary-color: ${({ theme }) => get(theme, 'colors', [])[0]};
  --theme_secondary-color: ${({ theme }) => get(theme, 'colors', [])[1]};
  --theme_tertiary-color: ${({ theme }) => get(theme, 'colors', [])[2]};
  --theme_font: ${({ theme }) => get(theme, 'font')};

  background: var(--theme_secondary-color);
  color: var(--theme_primary-color);
  font-family: var(--theme_font);

  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  main {
    width: 100%;
  }

  a {
    color: var(--theme_primary-color);
  }
`

const AppMap = ({ app }: AppState) => ({ auth: app.auth, theme: app.theme })

export default connect(AppMap)(App)
