export type Styleable = {
  className?: string
}

export type AnyObject = {
  [key: string]: any
}

export enum StyleOptions {
  Fiction = 'FICTION',
  NonFiction = 'NONFICTION',
  Poetry = 'POETRY',
  Essay = 'ESSAY',
  Screenplay = 'SCREENPLAY',
}

export enum LensOptions {
  Gothic = 'GOTHIC',
  Shakespeare = 'SHAKESPEARE',
  Journalism = 'JOURNALISM',
  Hemingway = 'HEMINGWAY',
  Custom = 'CUSTOM',
}

export enum Scenario {
  Synonym = 'SYNONYM',
  NextWord = 'NEXT_WORD',
  GAP = 'GAP',
}

export enum EditorMode {
  Writing = 'WRITING',
  Edit = 'EDIT',
}

export enum AppThemeName {
  Dark = 'DARK',
  Light = 'LIGHT',
}

export type AppTheme = {
  colors: Array<string>
  font: string
}

export type Action = {
  type: string
  payload: any
}

export type Annotation = {
  sentiment: number
  targetLength: number
  targetSentiment: number
}

export type Auth = {
  status: string
  email: string | null
  uid: string
  loading: boolean
}

export type DocumentSettings = {
  style: string
  lens: string
}

export type DocumentSummary = {
  id: string
  title: string
  // updatedAt: string
}

export type Suggestion = {
  text: string
  confidence: number
}
export type SuggestionSettings = {
  scenarioType: Scenario
  wordArray: Array<string>
}

export type DialogueDocument = {
  annotations: Array<Annotation>
  docBody: string
  documentMeta: { id: string; title: string; settings: { lens: string; style: string } }
  id: string
  mlJobRunning: boolean
  suggestions: Array<Suggestion>
  suggestionsSettings: SuggestionSettings
}

export type EditorState = {
  document: NetworkState<DialogueDocument>
  mode: EditorMode
}

export type DocumentsState = {
  documents: NetworkState<Array<DialogueDocument>>
}

export type NetworkState<Data> = {
  loading: boolean
  error?: Error
  data?: Data
}

export type DocumentListState = NetworkState<Array<DocumentSummary>>

export type AppState = {
  docStyleOptions: Array<StyleOptions>
  editor: EditorState
  documents: DocumentListState
  app: { auth: Auth; theme: AppTheme }
}
