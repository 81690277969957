import Papa from 'papaparse'

export const DownloadAsCsv = (data: Array<any>, filename: string) => {
  const csvData = new Blob([Papa.unparse(data)], { type: 'text/csv;charset=utf-8;' })
  const encodedUri = window.URL.createObjectURL(csvData)
  const filenameWithExtension = `${filename}.csv`

  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(data, filename)
  }

  const anchor = document.createElement('a')
  anchor.href = encodedUri
  anchor.download = filenameWithExtension
  anchor.hidden = true

  anchor.click()
}

export const DownloadAsText = (data: string, filename: string) => {
  const parsedData = new Blob([data], { type: 'text/csv;charset=utf-8;' })
  const encodedUri = window.URL.createObjectURL(parsedData)
  const filenameWithExtension = `${filename}.txt`

  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(data, filename)
  }

  const anchor = document.createElement('a')
  anchor.href = encodedUri
  anchor.download = filenameWithExtension
  anchor.hidden = true

  anchor.click()
}

export const DownloadAsImage = (data: any, filename: string) => {
  const filenameWithExtension = `${filename}.png`

  const anchor = document.createElement('a')
  anchor.href = data
  anchor.download = filenameWithExtension
  anchor.hidden = true

  anchor.click()
}
