export const STYLES = ['FICTION', 'NONFICTION', 'POETRY', 'ESSAY', 'SCREENPLAY']

export enum Routes {
  Login = '/login',
  CreateDocument = '/create-document',
  DocumentList = '/document-list',
  Editor = '/editor/:documentId',
}

export enum AUTH_STATUS {
  ANONYMOUS = 'ANONYMOUS',
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_OUT = 'SIGNED_OUT',
  ATTEMPTING_CHANGE = 'ATTEMPTING_CHANGE',
}
