import { EditorState, EditorMode } from '../../types'
import {
  // UPDATE TITLE
  UPDATE_TITLE_REQUEST,
  UPDATE_TITLE_SUCCESS,
  UPDATE_TITLE_ERROR,
  // UPDATE DOCUMENT
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_ERROR,
  // GET DOCUMENT
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_ERROR,
  // SET SUGGESTIONS
  // SET_SUGGESTIONS_SETTINGS_REQUEST,
  // SET_SUGGESTIONS_SETTINGS_SUCCESS,
  // SET_SUGGESTIONS_SETTINGS_ERROR,
  // CLEAR SUGGESTIONS
  CLEAR_ML_SUGGESTIONS_SUCCESS,
  // UTILS
  SET_ML_JOB_RUNNING,
  SET_ML_DOCUMENT_UPDATES,
  CLEAR_DOCUMENT,
} from './editor.actions'
import { set, get } from 'lodash-es'
import { AnyAction } from 'redux'

const inititalState: EditorState = {
  document: {
    loading: false,
  },
  mode: EditorMode.Writing,
}

export default function editorReducer(state = inititalState, action: AnyAction) {
  const { payload, type } = action
  switch (type) {
    // UPDATE TITLE
    case UPDATE_TITLE_REQUEST:
      return { ...state, document: { ...state.document, loading: true } }
    case UPDATE_TITLE_SUCCESS:
      const updatedTitle = set(state.document, ['data', 'documentMeta', 'title'], payload)
      return { ...state, document: { ...updatedTitle, loading: false } }
    case UPDATE_TITLE_ERROR:
      return { ...state, document: { ...state.document, loading: false, error: payload } }
    // UPDATE DOCUMENT
    case UPDATE_DOCUMENT_REQUEST:
      return { ...state, document: { ...state.document, loading: true } }
    case UPDATE_DOCUMENT_SUCCESS:
      return { ...state, document: { ...state.document, loading: false } }
    case UPDATE_DOCUMENT_ERROR:
      return { ...state, document: { ...state.document, loading: false, error: payload } }
    // GET DOCUMENT
    case GET_DOCUMENT_REQUEST:
      return { ...state, document: { loading: true } }
    case GET_DOCUMENT_SUCCESS:
      return { ...state, document: { loading: false, data: payload } }
    case GET_DOCUMENT_ERROR:
      return { ...state, document: { loading: false, error: payload } }

    // UPDATE ANNOTATION
    case SET_ML_JOB_RUNNING:
      return { ...state, document: { ...state.document, loading: false, data: { ...state.document.data, mlJobRunning: payload } } }
    case SET_ML_DOCUMENT_UPDATES:
      const { annotations, mlJobRunning, suggestions } = payload
      return {
        ...state,
        document: { ...state.document, loading: false, data: { ...state.document.data, annotations, mlJobRunning, suggestions } },
      }
    // CLEARN ML SUGGESTIONS
    case CLEAR_ML_SUGGESTIONS_SUCCESS:
      return { ...state, document: { ...state.document, loading: false, data: { ...get(state, 'document.data'), suggestions: [] } } }
    case CLEAR_DOCUMENT:
      return { ...state, document: { data: undefined, loading: false } }
    default:
      return state
  }
}
