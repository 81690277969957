import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

// TODO update apollo-boost after this issue is resolved: https://github.com/apollographql/apollo-client/issues/3327
const API_PATH = 'https://api.dialogues.ai:5000/'

export const client = new ApolloClient({
  link: new HttpLink({ uri: API_PATH, fetchOptions: { rejectUnauthorized: false } }),
  cache: new InMemoryCache(),
})
