import React, { SFC } from 'react'
import styled from 'styled-components'
import { black } from '../style/colors'
import { Styleable } from '../types'

const Span = styled.span``

type Props = {
  wordCount: number
} & Styleable

const RawFooter: SFC<Props> = ({ className, wordCount }) => {
  return (
    <div className={className}>
      <Span />
      <Span>
        Read time: {Math.round(wordCount / 150)}
        mins
      </Span>
      <Span>Word count: {wordCount}</Span>
      <Span />
    </div>
  )
}

const Footer = styled(RawFooter)`
  background: var(--theme_secondary-color);
  color: var(--theme_primary-color);

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  height: 40px;
  border-top: 1px solid ${black};

  ${Span} {
    flex-grow: 1;
  }
`

export default Footer
