import normalize from './normalize'
import { mobile } from './breakpoints'
import { fontStack } from './typography'

export default `
  ${normalize()}

  ::selection {
    background: cyan;
    color: magenta;
  }
  ::-moz-selection {
    background: cyan;
    color: magenta;
  }

  *, *:before, *:after { box-sizing: inherit; }

  html {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    font-size: 16px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    margin: 0;
    min-height: 100%;
    font-family: ${fontStack};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    overflow: hidden;
    height: 100%;
  }

  body {
    height: 100%;
    overflow: auto;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }
  

  #app {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${mobile}px) {
    body {
      font-size: 18px;
    }
  }
`
