import React, { PureComponent, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Button } from '../style/button'
import { thunder, black } from '../style/colors'
import { Text } from '../style/typography'
import loginBackground from '../writing2.jpg'

type PasscodeInputProps = {
  backgroundColor: boolean
}

const LoginWrapper = styled.div`
  height: 100vh;
  background-image: url(${loginBackground});
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
`
const LoginWindow = styled.div`
  max-width: 50%;
`
const PageTitle = styled(Text)`
  font-size: 36px;
  color: ${black};
`
const LoginButton = styled(Button)`
  border-width: 1px;
  border-style: solid;
  border-color: var(--theme_primary-color);
  box-shadow: 0px 0px 1px 0px;
  color: #000;
  padding: 0.5rem 1.5rem;
  margin: 0.5rem;
  background-color: #fff;
`

const PasscodeInput = styled.input`
  border-width: 1px;
  border-style: solid;
  border-color: var(--theme_primary-color);
  box-shadow: 0px 0px 1px 0px;
  color: #000;
  color: ${thunder};
  padding: 0.5rem 1.5rem;
  margin: 0.5rem;
  border-radius: 4px;
  background-color: ${(props: PasscodeInputProps) => (props.backgroundColor ? `#33CC66` : ``)};

  &:focus {
    outline: none;
  }
`

type Props = {
  signInRequest: () => void
  facebookSignInRequest: () => void
}

class RawLogin extends PureComponent<Props, {}> {
  state = {
    passcode: '',
  }

  handleLogin = () => {
    this.props.signInRequest()
  }
  handleFacebookLogin = () => {
    this.props.facebookSignInRequest()
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    this.setState(() => ({ passcode: e.target.value }))
  }
  render() {
    const accessCode = `superman`
    const { passcode } = this.state
    return (
      <LoginWrapper>
        <LoginWindow>
          <PageTitle>Dialogues Writer</PageTitle>
          <LoginButton onClick={this.handleLogin}>Google Login</LoginButton>
          <LoginButton onClick={this.handleFacebookLogin}>Facebook Login</LoginButton>
        </LoginWindow>
      </LoginWrapper>
    )
  }
}

const Login = styled(RawLogin)``

export default Login
