import styled from 'styled-components'
import { HTMLProps } from 'react'
import { Styleable } from '../../types'
import { ternaryValueFromProp } from '../../utils/logic.utils'

export const globalSpacing = 24

export const Content = styled.div<any>`
  width: calc(100% - ${globalSpacing * 2}px);
  max-width: 768px;
  margin: 0;
`
Content.displayName = 'Content'

export const ContentWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
ContentWrapper.displayName = 'ContentWrapper'

type FlexProps = {
  centerv?: boolean
  centerh?: boolean
}

export const FlexColumn = styled<HTMLProps<HTMLDivElement> & FlexProps & Styleable, 'div'>('div')`
  display: flex;
  flex-direction: column;
  align-items: ${ternaryValueFromProp('centerh', 'center', 'flex-start')};
  justify-content: ${ternaryValueFromProp('centerv', 'center', 'flex-start')};
`
FlexColumn.defaultProps = {
  centerv: false,
  centerh: false,
}
FlexColumn.displayName = 'FlexColumn'

export const FlexRow = styled<HTMLProps<HTMLDivElement> & FlexProps & Styleable, 'div'>('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 100%;
  align-items: ${ternaryValueFromProp('centerv', 'center', 'flex-start')};
  justify-content: ${ternaryValueFromProp('centerh', 'center', 'flex-start')};
`
FlexRow.defaultProps = {
  centerv: false,
  centerh: false,
}
FlexRow.displayName = 'FlexRow'
