import React, { Component } from 'react'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'
import htmlToText from 'html-to-text'

import { Styleable, AppThemeName, DialogueDocument, Annotation } from '../../types'
import { ternaryValueFromProp } from '../../utils/logic.utils'
import { Button } from '../../style/button'
import { pingMLServer } from '../../graphql/queries'
import { documentsRef } from '../../firebase/firebase'
import { round } from 'lodash-es'
import { store } from '../..'
import { setAppTheme } from '../../store/app/app.actions'
import { Heading } from '../../style/typography'
import { FlexColumn } from './content.component'
import { DownloadAsCsv, DownloadAsText, DownloadAsImage } from '../../utils/download.utils'

type Props = {
  show: boolean
  documentTitle: string
  documentBody: string
  document: DialogueDocument
} & Styleable

enum ExportType {
  text = 'text',
  withSentiment = 'withSentiment',
}

export const sidebarWidth = 300
const SidebarContainer = styled(FlexColumn)``

const WhiteButton = styled(Button)`
  border-width: 2px;
  border-style: solid;
  border-color: var(--theme_primary-color);
  color: var(--theme_primary-color);
  padding: 0.5rem 1.5rem;
  margin: 0.5rem;
`

export class RawSidebar extends Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  //TODO Switch to Cloud Run
  crunchIt = () => {
    const { document } = this.props
    documentsRef.doc(document.documentMeta.id).update({ mlJobRunning: true })
    pingMLServer(document.documentMeta.id, 'doc')
  }

  handleChartClick = (chartEelements: any) => {
    const index = chartEelements[0]._index
    const element = document.querySelector(`.ql-editor p:nth-of-type(${index + 1})`)
    if (element) {
      const { top } = element.getBoundingClientRect()
      console.log({ chartEelements, element, top })
      window.scrollTo(0, top)
    }
  }

  handleExport = (type: ExportType) => () => {
    const {
      document: { annotations },
      documentTitle,
      documentBody,
    } = this.props

    if (type === ExportType.text) {
      return DownloadAsText(htmlToText.fromString(documentBody), documentTitle)
    }
    const paragraphs = htmlToText.fromString(this.props.documentBody).split('\n\n')
    const downloadData = annotations.map((item: Annotation, index: number) => ({
      paragraph_number: index + 1,
      sentiment: item.sentiment,
      paragraph_text: paragraphs[index],
    }))

    return DownloadAsCsv(downloadData, documentTitle)
  }

  exportImage = () => {
    const el = document.querySelector('.chartjs-render-monitor')
    const data = (el as HTMLCanvasElement).toDataURL('image/png')
    DownloadAsImage(data, `${this.props.documentTitle}--setiment-graph`)
  }

  toggleTheme(themeName: AppThemeName) {
    store.dispatch(setAppTheme(themeName))
  }

  render() {
    const { className, document } = this.props
    const chartData = {
      labels: document.annotations.map((_, index) => `¶ ${index + 1}`),
      datasets: [
        {
          label: 'Sentiment',
          pointBackgroundColor: 'rgba(255, 255, 255, 1)',
          borderWidth: 2,
          borderColor: 'rgba(52, 152, 219, 0.75)',
          data: document.annotations.map((object) => round(object.sentiment, 2)),
        },
      ],
    }
    return (
      <SidebarContainer className={className}>
        <div>
          <WhiteButton onClick={this.crunchIt}>Crunch it</WhiteButton>
          <WhiteButton onClick={() => this.toggleTheme(AppThemeName.Dark)}>Dark</WhiteButton>
          <WhiteButton onClick={() => this.toggleTheme(AppThemeName.Light)}>Light</WhiteButton>
          <Line getElementAtEvent={this.handleChartClick} data={chartData} width={300} height={300} />
        </div>
        <FlexColumn>
          <Heading level={4}>Export</Heading>
          <WhiteButton onClick={this.exportImage}>Chart (.png)</WhiteButton>
          <WhiteButton onClick={this.handleExport(ExportType.text)}>Text (.txt)</WhiteButton>
          <WhiteButton onClick={this.handleExport(ExportType.withSentiment)}>Text + Sentiment (.csv)</WhiteButton>
        </FlexColumn>
      </SidebarContainer>
    )
  }
}

export const Sidebar = styled(RawSidebar)`
  width: ${sidebarWidth}px;
  justify-content: space-between;
  height: 100vh;
  padding: 40px 10px 60px 10px;
  background: var(--theme_secondary-color);
  border-left: 1px solid var(--theme_tertiary-color);
  position: fixed;
  right: 0;
  top: 0;
  transform: translate(${ternaryValueFromProp('show', 0, sidebarWidth)}px, 0);
  will-change: transform;
  transition: transform 200ms;
  z-index: -1;

  ${WhiteButton} {
    line-height: 0.5;
  }
`
